/*
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-08-05 16:23:45
 * @LastEditTime: 2022-08-31 11:01:00
 */
import axios from '@/utils/request'
let Api = {
    activityList: "/activity/list",
    activityAdd: "/activity/createActivity",
    activityDetail: "/activity/",
    activityEdit: "/activity/edit?id=",
    activityDelete: "/activity/",
    manhourModal: "/activity/downloadTemplate",
    manhourFileUpload: "/activity/uploadWorkingHours",
    activitySearch: "/activity/inquire",
    activityVerifyList: "/advancedActivity/list",
    activityVerify: "/activity/audit",
    myBaseList: "/coConstructionBase/list",
    myBaseAdd: "/coConstructionBase/",
    activityListAll: "/activity/myActivitylist",
    baseDisable: "/coConstructionBase/changeState/",
    activitySignList: "/activity/signInList",
    activitySignDetail: "/activity/signDetails",
    myBaseDetail: "/coConstructionBase/details/",
    enableEditActivity: "/activity/editPermission",
    activityFedback: "/feedback/list",
    verifyActivityDownload: "/advancedActivity/export",
    activityTimeSheetExport: "/activity/activityWorkingExport",
    coConstructionBaseEdit:"/coConstructionBase/edit/"
}
//活动编辑
export function coConstructionBaseEdit(id,params) {
    return axios({
        method: 'put',
        url: Api.coConstructionBaseEdit + id,
        params: params
    })
}
// 活动列表
export function getActivityList(params) {
    return axios({
        method: 'get',
        url: Api.activityList,
        params: params
    })
}
// 新建一般活动
export function addActivity(params) {
    return axios({
        method: 'post',
        url: Api.activityAdd,
        data: params
    })
}
// 活动详情
export function getActivityDetail(id, params) {
    return axios({
        method: 'get',
        url: Api.activityDetail + id,
        params: params
    })
}
// 编辑一般活动
export function editActivity(id, params) {
    return axios({
        method: 'put',
        url: Api.activityEdit + id,
        data: params
    })
}
// 删除活动
export function deleteActivity(id, params) {
    return axios({
        method: 'delete',
        url: Api.activityDelete + id,
        params: params
    })
}

// 工时表模板
export function getManhourModal(params) {
    return axios({
        method: 'get',
        url: Api.manhourModal,
        params: params
    })
}
// 新建一般活动
export function uploadManhourFile(params) {
    return axios({
        method: 'post',
        url: Api.manhourFileUpload,
        data: params
    })
}
// 活动查询
export function searchActivity(params) {
    return axios({
        method: 'get',
        url: Api.activitySearch,
        params: params
    })
}
// 一般活动审核列表
export function listActivityVerify(params) {
    return axios({
        method: 'get',
        url: Api.activityVerifyList,
        params: params
    })
}
// 一般活动审核
export function verifyActivity(params) {
    return axios({
        method: 'post',
        url: Api.activityVerify,
        data: params
    })
}
// 我的共建基地列表
export function getMyBaseList(id, params) {
    return axios({
        method: 'get',
        url: Api.myBaseList,
        params: params
    })
}
// 新建共建基地
export function addMyBase(params) {
    return axios({
        method: 'post',
        url: Api.myBaseAdd,
        data: params
    })
}
// 我的全部一般活动
export function listAllactivity(params) {
    return axios({
        method: 'get',
        url: Api.activityListAll,
        params: params
    })
}
// 共建基地禁用/启用
export function disableBase(id, params) {
    return axios({
        method: 'get',
        url: Api.baseDisable + id,
        params: params
    })
}
// 活动签到列表
export function listActivitySign(params) {
    return axios({
        method: 'get',
        url: Api.activitySignList,
        params: params
    })
}
// 活动签到详情
export function detailActivitySign(params) {
    return axios({
        method: 'get',
        url: Api.activitySignDetail,
        params: params
    })
}
// 共建基地详情
export function detailMyBase(id, params) {
    return axios({
        method: 'get',
        url: Api.myBaseDetail + id,
        params: params
    })
}
// 活动编辑权限
export function editActivityEnable(params) {
    return axios({
        method: 'put',
        url: Api.enableEditActivity,
        params: params
    })
}
// 活动反馈
export function fedbackActivity(params) {
    return axios({
        method: 'get',
        url: Api.activityFedback,
        params: params
    })
}

export function downLoadVerifyActivity(params) {
    return axios({
        method: 'get',
        url: Api.verifyActivityDownload,
        params: params,
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json;charset=UTF-8'
        },
        withCredentials: true,
        observe: 'response',
        responseType: 'arraybuffer',
    })
}
export function exportActivityTimeSheet(params) {
    return axios({
        method: 'get',
        url: Api.activityTimeSheetExport,
        params: params,
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json;charset=UTF-8'
        },
        withCredentials: true,
        observe: 'response',
        responseType: 'arraybuffer',
    })
}
