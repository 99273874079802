/*
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-07-20 17:27:12
 * @LastEditTime: 2022-09-02 17:28:53
 */
import axios from '@/utils/request'
let Api = {
    baseVerifyList: "/coConstructionBase/auditList",
    baseVerify: "/coConstructionBase/",
    // baseActivityVerifyList: "/coConstructionBase/activityList",
    baseActivityVerifyList: "/advancedActivity/comList",
    baseActivityList: "/coConstructionBase/",
    baseDelete: "/coConstructionBase/",
    baseActivityAll: "/coConstructionBase/coActivity",
    baseActivityDownload: "/advancedActivity/comExport",
}
// 共建基地审核列表
export function getBaseVerifyList(params) {
    return axios({
        method: 'get',
        url: Api.baseVerifyList,
        params: params
    })
}
// 共建基地审核
export function verifyBase(id, params) {
    return axios({
        method: 'put',
        url: Api.baseVerify + id,
        params: params
    })
}
// 共建基地活动审核列表
export function getBaseActivityVerifyList(params) {
    return axios({
        method: 'get',
        url: Api.baseActivityVerifyList,
        params: params
    })
}
// 共建基地活动列表
export function getBaseActivityList(id, params) {
    return axios({
        method: 'get',
        url: Api.baseActivityList + id,
        params: params
    })
}
// 共建基地删除
export function deleteBase(id, params) {
    return axios({
        method: 'delete',
        url: Api.baseDelete + id,
        params: params
    })
}
// 共建基地活动概况
export function allBaseActivity(params) {
    return axios({
        method: 'get',
        url: Api.baseActivityAll,
        params: params
    })
}

export function downloadBaseActivity(params) {
    return axios({
        method: 'get',
        url: Api.baseActivityDownload,
        params: params,
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json;charset=UTF-8'
        },
        withCredentials: true,
        observe: 'response',
        responseType: 'arraybuffer',
    })
}
