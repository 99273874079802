<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-06-30 10:44:52
-->
<template>
	<div class="baseVerify">
		<div class="baseVerify-content">
			<div class="activityVerify-table">
				<el-table :data="tableData" border>
					<el-table-column width="140px" prop="id" label="共建基地ID">
					</el-table-column>
					<el-table-column prop="name" label="共建基地">
					</el-table-column>
					<el-table-column prop="organization" label="所属组织">
					</el-table-column>
					<el-table-column width="100px" prop="totalWorkingHours" label="总工时">
					</el-table-column>
					<el-table-column width="100px" prop="activityNum" label="活动次数">
					</el-table-column>
					<el-table-column width="100px" prop="activityPeoNum" label="活动人次">
					</el-table-column>
					<el-table-column width="160px" prop="state" label="当前状态">
						<template slot-scope="scope">
							<span>{{scope.row.state == 0?'停用': scope.row.state == 1?'已启用': ''}}</span>
						</template>
					</el-table-column>
					<el-table-column width="200px" prop="action" label="操作">
						<template slot-scope="scope">
							<div class="table-action">
								<span @click="actionFn('detail', scope)">详情</span>
								<el-divider direction="vertical"></el-divider>
								<span :class="{'table-action-red': scope.row.state == 1}"
									@click="actionFn('status', scope)">{{scope.row.state == 0? "开启":scope.row.state == 1? "停用":""}}</span>
								<!-- <el-divider direction="vertical"></el-divider>
								<span @click="actionFn('delete', scope)">删除</span> -->
							</div>
						</template>
					</el-table-column>
				</el-table>
				<div class="common-pageNation">
					<el-pagination background @current-change="currentChange" :current-page.sync="pageIndex"
						:page-size="pageSize" layout="total, prev, pager, next" :total="totalCount">
					</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		allBaseActivity,
		deleteBase,
	} from "@/api/base";
	import {
		disableBase,
	} from "@/api/activity";
	export default {
		data() {
			return {
				totalCount: 0,
				pageIndex: 1,
				pageSize: 10,
				tableData: []
			};
		},
		mounted() {
			this.getAllBaseActivity();
		},
		methods: {
			// 共建基地活动概况列表
			getAllBaseActivity() {
				let params = {
					pageIndex: this.pageIndex,
					pageSize: this.pageSize,
				};
				allBaseActivity(params).then(res => {
					if (res.code == 0) {
						this.tableData = res.data;
						this.totalCount = res.page.totalCount * 1
					}
				});
			},
			currentChange(val) {
				this.pageIndex = val;
				this.getAllBaseActivity();
			},
			actionFn(type, scope) {
				switch (type) {
					case "delete":
						this.$confirm('确定删除此共建基地活动概况?', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'error'
						}).then(() => {
							deleteBase(scope.row.id, {
								id: scope.row.id
							}).then((res) => {
								if (res.code == 0) {
									this.pageIndex = 1;
									this.getAllBaseActivity();
									this.$message({
										type: 'success',
										message: '删除成功!'
									});
								}
							})
						}).catch(() => {});
						break;
					case "status":
						if (scope.row.state == 0) {
							this.$confirm('确定开启此共建基地活动吗?', '开启共建基地活动', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {
								let params = {
									id: scope.row.id,
									state: 1
								}
								disableBase(scope.row.id, params).then((res) => {
									if (res.code == 0) {
										scope.row.state = 1
										this.$message({
											type: 'success',
											message: '开启成功!'
										});
										this.$forceUpdate()
									}
								})
							}).catch(() => {});
						} else {
							this.$confirm('确定停用此共建基地活动吗?', '停用共建基地活动', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {
								let params = {
									id: scope.row.id,
									state: 0
								}
								disableBase(scope.row.id, params).then((res) => {
									if (res.code == 0) {
										scope.row.state = 0
										this.$message({
											type: 'success',
											message: '停用成功!'
										});
										this.$forceUpdate()
									}
								})
							}).catch(() => {});
						}
						break;
					case "detail":
						// let routeEdit = this.$router.resolve({
						// 	path:"/activityMy/baseDetail",
						// 	query:{baseId:scope.row.id,visitType:'baseAll'}
						// })
						// window.open(routeEdit.href,"_blank")
						this.$router.push({
							path: `/activityMy/baseDetail?baseId=${scope.row.id}&&visitType=baseAll`
						})
						break;
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	.baseVerify {
		.baseVerify-content {
			padding: 20px 20px;
		}
	}

	.table-action-red {
		color: red !important;
	}
</style>
